import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';  // For authentication
import { getFirestore} from 'firebase/firestore'; // For Firestore
import { getDatabase } from 'firebase/database'; // For Realtime Database (if needed)
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import { getVertexAI, getGenerativeModel } from "firebase/vertexai";

// Your Firebase configuration object (replace with your actual config)
const firebaseConfig = {
  apiKey: "AIzaSyB3wdykLnfjx0FJNibPh3ILCxPUP0xy0_A",
  authDomain: "open-psychic.firebaseapp.com",
  projectId: "open-psychic-143d5",
  storageBucket: "open-psychic-143d5.appspot.com",
  messagingSenderId: "189441166810",
  appId: "1:189441166810:android:acdbb571b5b8ae114044e3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get Firebase services
const auth = getAuth(app);
const firestore = getFirestore(app);
const database = getDatabase(app);
const messaging = getMessaging(app);
// const vertexAI = getVertexAI(app);
// const model = getGenerativeModel(vertexAI, { model: "gemini-2.0-flash" });

// Request Notification Permission
export const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, { vapidKey: "BKNMs85FDDonOIDoM9tiStSlGmmj7EeSAeEqazs0upWtuxiNLt7bChiOonRbVDcFvIpWkIKZEH2k1GtIXUG3fAQ" });
      console.log("FCM Token:", token);
      return token;
    } else {
      console.log("Permission denied");
    }
  } catch (error) {
    console.error("Error getting permission: ", error);
  }
};

// Listen for Incoming Messages
onMessage(messaging, (payload) => {
  console.log("Message received: ", payload);
  new Notification(payload.notification.title, {
    body: payload.notification.body,
    icon: payload.notification.icon,
  });
});

// Export Firebase services
export { auth, firestore, database, messaging };

// If you need to work with Firestore collections elsewhere in your code, you can use
// functions like `collection()` and `getDocs()` directly, instead of methods on `firestore`.
