<template>
  <div class="settings-page">
    <!-- Header -->
    <div class="settings-header">
      <h1>Settings</h1>
    </div>

    <!-- Tabs -->
    <div class="settings-tabs">
      <button
        v-for="tab in tabs"
        :key="tab"
        :class="{ active: activeTab === tab }"
        @click="activeTab = tab"
      >
        {{ tab }}
      </button>
    </div>

  <!-- Account Section -->
<div v-if="activeTab === 'Account'" class="settings-section">
  <!-- Account Eligibility Card -->
  <div class="card">
    <h3>Account Eligibility</h3>
    <div class="form-group">
      <p>{{ accountStatusDescription }}</p>
      <div class="status-icon">
        <span :class="statusIconClass"></span>
        <p>Account Status: {{ profile.accountStatus }}</p>
      </div>
    </div>
  </div>

  <!-- Sign Out Card -->
  <div class="card">
    <h3>Sign Out</h3>
    <div class="form-group">
      <p>Are you sure you want to sign out?</p>
      <button class="btn danger" @click="signOut">Sign Out</button>
    </div>
  </div>
</div>

    <div v-if="activeTab === 'Profile'" class="settings-section">
      <div class="card">
      <div class="card">
        <h2>Profile</h2>

        <!-- <div class="card">
          <div
        class="backdrop-image"
        @click="selectImage('backdrop')"
          :style="{ backgroundImage: `url(${backdropImageURI || defaultImage})` }">

      <div class="update-section">
        <button @click="uploadImageToFirebase">Update</button>
      </div>

      <img :src="premiumIcon" alt="Premium Icon" class="premium-icon" />
      <input 
        type="file" 
        ref="fileInput" 
        @change="handleFileChange" 
        accept="image/*" 
        style="display: none" />
  </div>

 <div class="profile-image" @click="selectImage('profile')">
  <div class="profile-content">
    <img :src="profileImageURI || defaultImage" alt="Profile" class="profile-img" />
    <div class="profile-section">
      <div class="user-info">
        <p class="usertype">
          {{ profile.usertype === 'user' ? 'Seeker' : profile.usertype === 'psychic' ? 'Psychic' : 'Usertype' }}
        </p>
        <p class="username">@{{ profile.username || 'Username' }}</p>
        <p class="display-name">{{ profile.displayname || 'Display Name' }}</p>
      </div>
    </div>
  </div>
</div> -->

  <!-- </div> -->

        <!-- Username -->
        <div class="form-group">
          <label for="username">Username</label>
          <div class="input-group">
            <input
              id="username"
              type="text"
              v-model="profile.username"
              placeholder="Username"
            />
            <button class="btn-update" @click="updateField('username')">Update</button>
          </div>
        </div>

        <!-- Display Name -->
        <div class="form-group">
          <label for="displayName">Display Name</label>
          <div class="input-group">
            <input
              id="displayName"
              type="text"
              v-model="profile.displayname"
              placeholder="Display Name"
            />
            <button class="btn-update" @click="updateField('displayName')">Update</button>
          </div>
        </div>

        <!-- Bio -->
        <div class="form-group">
          <label for="bio">Biography</label>
          <div class="input-group">
            <textarea
              id="bio"
              v-model="profile.bio"
              placeholder="Write a short introduction..."
              rows="4"
            ></textarea>
            <p class="char-count">{{ 275 - profile.bio.length }} characters left</p>
            <button class="btn-update" @click="updateField('bio')">Update</button>
          </div>
        </div>

        <!-- Email -->
        <div class="form-group">
          <label for="email">Email</label>
          <div class="input-group">
            <input
              id="email"
              type="email"
              v-model="profile.email"
              disabled
              placeholder="example@example.com"
            />
            <button class="btn-update" disabled>Update</button>
          </div>
        </div>
      </div>
</div>
      <div class="card">

      <!-- Psychic Settings (Conditionally Rendered) -->
      <div v-if="profile.isPsychic" class="card">
        <h2>Psychic Settings</h2>
        
        <!-- Country of Origin -->
        <div class="form-group">
          <label for="countryOrigin">Country of Origin</label>
          <div class="input-group">
            <select
              id="countryOrigin"
              v-model="psychicProfile.countryOrigin"
            >
              <option v-for="country in countries" :key="country" :value="country">
                {{ country }}
              </option>
            </select>
            <button class="btn-update" @click="updateField('countryOrigin')">Update</button>
          </div>
        </div>

        <!-- Year of Origin -->
        <div class="form-group">
          <label for="yearOrigin">Year of Origin</label>
          <div class="input-group">
            <input
              id="yearOrigin"
              type="number"
              v-model="psychicProfile.yearOrigin"
              maxlength="4"
              placeholder="XXXX"
            />
            <button class="btn-update" @click="updateField('yearOrigin')">Update</button>
          </div>
        </div>

        <!-- Psychic Category -->
        <div class="form-group">
          <label for="psychicCategory">Psychic Category</label>
          <div class="input-group">
            <select
              id="psychicCategory"
              v-model="profile.psychicondisplaycategory"
            >
              <option v-for="category in psychicCategories" :key="category" :value="category">
                {{ category }}
              </option>
            </select>
            <button class="btn-update" @click="updateField('psychicCategory')">Update</button>
          </div>
        </div>

        <!-- Display Status -->
        <div class="form-group">
          <label class="switch-label">
            <span>Psychic On Display</span>
            <div class="switch">
              <input
                type="checkbox"
                v-model="profile.psychicondisplay"
              />
              <span class="slider"></span>
            </div>
          </label>
        </div>
      </div>
      </div>
      <div v-if="profile.isPsychic" class="card">
      <PsychicAvailabilityComponent :psychic-id="currentUser.uid"/>
      </div>
    </div>

<!-- Plan Section -->
<div v-if="activeTab === 'Plan'" class="settings-section">
  <div class="card">
    <h2>Membership</h2>
    <div class="form-group">
      <p><strong>Status:</strong> {{ profile.isPremium ? 'Premium All Access Plan' : '100% Free Tier Plan' }}</p>
      <p>{{ profile.isPremium ? 
          'Premium All Access for $29.99/month. Enjoy unlimited access to all features. We Thank You!' :
          '100% Free Tier. Enjoy 24/7 Support for Your Mystical Needs. No payment required.' 
        }}
      </p>
      <button @click="checkAndUpdatePlan">Change Plan</button>
    </div>
  </div>
</div>

    <!-- Email Notifications Section -->
    <div v-if="activeTab === 'Email Notifications'" class="settings-section">
    <div class="card">
      <h2>Email Subscription Preferences</h2>
      
      <!-- Events and Experiences -->
      <div class="form-group">
        <div class="checkbox-container">
          <input 
            type="checkbox" 
            id="events" 
            v-model="emailPreferences.events"
          />
          <label for="events" class="checkbox-label">Events and Experiences</label>
        </div>
        <p>Receive the latest updates on our workshops, in-person events, virtual events, and other creator-focused experiences.</p>
      </div>

      <!-- Community and Education -->
      <div class="form-group">
        <div class="checkbox-container">
          <input 
            type="checkbox" 
            id="community" 
            v-model="emailPreferences.community"
          />
          <label for="community" class="checkbox-label">Community and Education</label>
        </div>
        <p>Get updates on programs and trending conversations in our Creator Community Discord.</p>
      </div>

      <!-- User & Market Research -->
      <div class="form-group">
        <div class="checkbox-container">
          <input 
            type="checkbox" 
            id="research" 
            v-model="emailPreferences.research"
          />
          <label for="research" class="checkbox-label">User & Market Research</label>
        </div>
        <p>Receive emails about opportunities to take part in user research and surveys to help improve the product.</p>
      </div>

      <!-- Marketing and Promotions -->
      <div class="form-group">
        <div class="checkbox-container">
          <input 
            type="checkbox" 
            id="marketing" 
            v-model="emailPreferences.marketing"
          />
          <label for="marketing" class="checkbox-label">Marketing and Promotions</label>
        </div>
        <p>Receive general marketing and promotional emails from Open Psychic.</p>
      </div>

      <!-- Creator Hub -->
      <div class="form-group">
        <div class="checkbox-container">
          <input 
            type="checkbox" 
            id="creatorHub" 
            v-model="emailPreferences.creatorHub"
          />
          <label for="creatorHub" class="checkbox-label">Creator Hub</label>
        </div>
        <p>Your boost of inspiration and know-how, featuring creator stories, entrepreneurship advice, and invitations to workshops and events.</p>
      </div>

      <!-- Email Notifications -->
      <div class="form-group">
        <div class="checkbox-container">
          <input 
            type="checkbox" 
            id="notifications" 
            v-model="emailPreferences.notifications"
          />
          <label for="notifications" class="checkbox-label">Email Notifications</label>
        </div>
        <p>Stay up to date with email reminders about your page.</p>
      </div>

      <!-- Unsubscribe from all -->
      <div class="form-group">
        <div class="checkbox-container">
          <input 
            type="checkbox" 
            id="unsubscribeAll" 
            v-model="emailPreferences.unsubscribeAll"
            @change="handleUnsubscribeAll"
          />
          <label for="unsubscribeAll" class="checkbox-label">Unsubscribe from all marketing emails</label>
        </div>
      </div>

      <div class="button-container">
        <button @click="updatePreferences" class="update-btn">Update</button>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, storage } from '@/firebase'; // Adjust with your Firebase import
import { getDownloadURL, ref as storageRef, uploadBytes } from 'firebase/storage';
import PsychicAvailabilityComponent from "./PsychicAvailabilityComponent.vue";

export default {
  components: {
    PsychicAvailabilityComponent,
  },
  data() {
    return {
      currentUser: auth.currentUser,
      profileImageURI: null,
      backdropImageURI: null,
      selectedView: '',
      defaultImage: require('@/assets/openpsychiclogo.png'),
      premiumIcon: require('@/assets/crown.png'),
      emailPreferences: {
        productNews: false,
        eventsExperiences: false,
        communityEducation: false,
        userResearch: false,
        marketingPromotions: false,
        unsubscribeAll: false,
        creatorHub: false,
        pageReminders: false,
      },
      tabs: ["Account", "Profile", "Plan", "Email Notifications"],
      activeTab: "Profile",
      profile: {
        accountStatus: "Active",
        userid: "",
        email: "",
        displayname: "",
        username: "",
        usertype: "",
        firstname: "",
        lastname: "",
        bio: "",
        profileimgsrc: "",
        displayimgsrc: "",
        questionsAvailable: 0,
        requestcount: 0,
        isPremium: false,
        isPsychic: false,
        psychicrating: null,
        psychicratingcount: null,
        psychicondisplay: false,
        psychicondisplaycategory: "",
      },
      psychicProfile: {
        countryOrigin: "",
        yearOrigin: "",
      },
      countries: ["USA", "UK", "Canada", "Australia"], // Add more countries
      psychicCategories: ["Tarot", "Astrology", "Medium", "Clairvoyant"] // Add your categories
    };
  },
  computed: {
    backdropImageSrc() {
      return this.backdropImageURI ? this.backdropImageURI : null;
    },
    accountStatusDescription() {
      const statusMap = {
        Active: "Your account is in good standing and fully operational.",
        "Pending Activation": "You need to complete your onboarding to activate your account.",
        Suspended: "Your account has been temporarily disabled due to violations of our platform agreement.",
        Premium: "Your support means everything to us. Thank you!",
        "Free Plan": "Keep on' enjoying our free plan with all access to our features.",
        Banned: "Your account has been permanently restricted.",
      };
      return statusMap[this.profile.accountStatus] || "Your account is in good standing and fully operational.";
    },
    statusIconClass() {
      const iconMap = {
        Active: "status-icon-good",
        "Pending Activation": "status-icon-pending",
        Suspended: "status-icon-warning",
        Premium: "status-icon-premium",
        "Free Plan": "status-icon-free",
        Banned: "status-icon-danger",
      };
      return iconMap[this.profile.accountStatus] || "status-icon-good";
    },
  },
  methods: {
loadImages(profileImgSrc, displayImgSrc) {
      this.profileImageURI = profileImgSrc !== ' ' ? profileImgSrc : this.defaultImage;
      this.backdropImageURI = displayImgSrc !== ' ' ? displayImgSrc : this.defaultImage;
    },
    selectImage(imageType) {
      // Set which image is being selected (profile or backdrop)
      this.selectedView = imageType;
      // Trigger file input click event
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (this.selectedView === 'backdrop') {
            this.backdropImageURI = e.target.result; // Update backdrop image
          } else if (this.selectedView === 'profile') {
            this.profileImageURI = e.target.result; // Update profile image
          }
        };
        reader.readAsDataURL(file);
      }
    },
    uploadImageToFirebaseStorage() {
      const userRef = storageRef(storage, `images/${this.$store.state.auth.uid}`);
      if (this.profileImageURI) {
        this.uploadImage(userRef, 'profileImg.jpg', this.profileImageURI, 'profileimgsrc');
      }
      if (this.backdropImageURI) {
        this.uploadImage(userRef, 'backdropImg.jpg', this.backdropImageURI, 'displayimgsrc');
      }
    },
    async uploadImage(storageRef, imageName, imageUri, field) {
      try {
        const imageRef = storageRef(storageRef, imageName);
        const response = await uploadBytes(imageRef, imageUri);
        const downloadUrl = await getDownloadURL(response.ref);
        // Update Firestore with the new image URL
        await this.$firestore.collection('users').doc(this.$store.state.auth.uid).update({
          [field]: downloadUrl,
        });
        this.$store.commit('setImageSrc', { [field]: downloadUrl }); // Assuming Vuex state management
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    },
    handleUnsubscribeAll() {
      if (this.emailPreferences.unsubscribeAll) {
        // Set all preferences to false if unsubscribeAll is checked
        Object.keys(this.emailPreferences).forEach(key => {
          if (key !== 'unsubscribeAll') {
            this.emailPreferences[key] = false;
          }
        });
      }
    },
    async fetchUserData() {
      try {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
          const db = getFirestore();
          const userDocRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            Object.assign(this.profile, userData);
            
            // Load email preferences if they exist
            if (userData.emailPreferences) {
              Object.assign(this.emailPreferences, userData.emailPreferences);
            }
            
            if (userData.usertype === "psychic") {
              this.profile.isPsychic = true;
              this.psychicProfile.countryOrigin = userData.countryOrigin || "";
              this.psychicProfile.yearOrigin = userData.yearOrigin || "";
            }
          }
        }
      } catch (error) {
        console.error("Error fetching user data: ", error);
      }
    },

    async updateField(field) {
      try {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
          const db = getFirestore();
          const userDocRef = doc(db, "users", user.uid);
          const updateData = {};
          
          updateData[field] = this.profile[field];
          await updateDoc(userDocRef, updateData);
        }
      } catch (error) {
        console.error(`Error updating ${field}: `, error);
      }
    },

    async signOut() {
      try {
        const auth = getAuth();
        await auth.signOut();
      } catch (error) {
        console.error("Error signing out: ", error);
      }
    },

    async updatePreferences() {
      try {
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
          console.error('No user authenticated');
          return;
        }

        const db = getFirestore();
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists()) {
          throw new Error('User document not found');
        }

        // Handle unsubscribeAll logic
        if (this.emailPreferences.unsubscribeAll) {
          Object.keys(this.emailPreferences).forEach(key => {
            if (key !== 'unsubscribeAll') {
              this.emailPreferences[key] = false;
            }
          });
        }

        // Update the email preferences
        await updateDoc(userDocRef, {
          emailPreferences: this.emailPreferences,
          updatedAt: new Date()
        });

        // Show success message (if you have a notification system)
        if (this.$notify) {
          this.$notify({
            type: 'success',
            message: 'Email preferences updated successfully'
          });
        } else {
          console.log('Email preferences updated successfully');
        }

      } catch (error) {
        console.error('Error updating email preferences:', error);
        if (this.$notify) {
          this.$notify({
            type: 'error',
            message: 'Failed to update email preferences'
          });
        }
      }
    }
  },
  mounted() {
    this.fetchUserData();
  }
};
</script>

<style scoped>

.update-section {
  text-align: right; /* Center the button */
  padding: 8px;
}

.update-section button {
  color: #D1A0F1; /* White text color */
  border: none; /* Remove default button border */
  padding: 12px; 
  font-size: 16px; /* Set font size */
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
  z-index: 20; /* Ensure the button is on top of the background */

}

.backdrop-image {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 6px;
  background-size: cover;
}

.premium-icon {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 32px;
  height: 32px;
}

.profile-section {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.profile-img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.user-info {
  display: flex;
  flex-direction: column;
}

.usertype {
  font-size: 24px;
  color: #fdd835;
  margin-bottom: 4px;
}

.username {
  font-size: 16px;
  color: #ba68c8;
}
/* Layout */
.settings-page {
  font-family: 'Inter', sans-serif;
  margin: 8px;
  padding: 20px;
  background-color: #2a093a; /* Deep purple background */
  box-shadow: 0 4px 12px rgba(36, 32, 32, 0.3);
  border-radius: 12px;
  color: #ECC3FF; /* Light purple text */
  width: 100vh;
  height: calc(100vh - 40px);
  overflow-y: auto; /* Enable vertical scrolling */
}

.settings-page::-webkit-scrollbar {
  display: none; /* Hide the scrollbar */
}

.settings-page {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;     /* Firefox */
}

.settings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.settings-header h1 {
  font-size: 28px;
  font-weight: 700;
  color: #ECC3FF;
}

/* Tabs */
.settings-tabs {
  display: flex;
  gap: 12px;
  margin: 20px 0;
  flex-wrap: wrap; /* Wrap tabs on smaller screens */
}

.settings-tabs button {
  padding: 12px 18px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  background: #F2D1FF; /* Light purple button */
  color: #2a093a; /* Dark purple text */
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
}

.settings-tabs button.active {
  background: #6200ea; /* Strong purple for active button */
  color: #ECC3FF; /* Light purple text for active state */
  font-weight: bold;
}

.settings-tabs button:hover {
  background: #D1A0F1; /* Light hover effect */
}

/* Section */
.settings-section {
  margin-top: 20px;
}

.settings-section h2 {
  font-size: 22px;
  font-weight: 700;
  color: #ECC3FF;
  margin-bottom: 20px;
}

/* Form */
.form-group {
  padding: 16px;
}

.form-group label {
  font-size: 14px;
  font-weight: 700;
  color: #F2D1FF;
  margin-bottom: 8px;
  display: block;
  text-align: start;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #D1A0F1;
  border-radius: 8px;
  outline: none;
  transition: border 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #6200ea; /* Purple focus state */
}

textarea {
  resize: none;
}

.char-count {
  font-size: 12px;
  color: #D1A0F1;
  margin-top: 5px;
}

/* Profile Photo */
.profile-photo {
  display: flex;
  align-items: center;
  gap: 15px;
}

.profile-photo img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #F2D1FF;
}

.profile-photo button {
  font-size: 14px;
  font-weight: 700;
  color: #6200ea;
  background: none;
  border: none;
  cursor: pointer;
}

.profile-photo button.danger {
  color: #E53935; /* Red for danger button */
}

/* Checkbox */
.checkbox {
  align-items: right;
  gap: 10px;
}

.checkbox-container {
  align-items: center;
  justify-content: flex-start; /* Align the checkbox to the left */
  gap: 12px; /* Space between checkbox and label */
}

.checkbox-container input {
  margin-right: 10px;
}

.checkbox-label {
  font-size: 16px;
  font-weight: 600;
  color: #ECC3FF; /* Light purple color */
}

.checkbox-description {
  text-align: center;
  margin-top: 8px;
  font-size: 14px;
  color: #AAA;
}

/* Card Styles */
.card {
  background-color: #3f1e4e;
  border-radius: 12px;
  padding: 16px;
  padding-bottom: 8px;
  margin-bottom: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.card h2 {
  font-size: 26px;
  font-weight: 700;
  color: #ECC3FF;
  text-align: left;
  margin: 8px;
}

.card h3 {
  font-size: 16px;
  font-weight: 700;
  color: #ECC3FF;
  text-align: left;
  margin: 8px;
}

.card .form-group {
  text-align: left;
}

.card .btn {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.card .btn.danger {
  background-color: #E53935;
  color: white;
}

.card .btn.danger:hover {
  background-color: #C62828;
}

.card .status-icon {
  display: flex;
  align-items: center;
}

.status-icon-good {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #66BB6A; /* Green for good status */
  margin-right: 10px;
}

.status-icon-pending {
  background-color: orange;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 10px;
}
.status-icon-warning {
  background-color: yellow;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 10px;
}
.status-icon-premium {
  background-color: gold;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 10px;
}
.status-icon-free {
  background-color: lightblue;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 10px;
}
.status-icon-danger {
  background-color: red;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 10px;
}
.status-icon-unknown {
  background-color: grey;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 10px;
}

.button-container {
  display: flex;
  justify-content: flex-end;  /* Aligns the button to the right */
}

.update-btn {
  background-color: #ECC3FF;  /* Light purple to match your theme */
  color: white;  /* Text color */
  border: none;
  padding: 12px 24px;  /* Padding around the text */
  font-size: 16px;  /* Font size */
  font-weight: bold;  /* Bold text */
  border-radius: 8px;  /* Rounded corners */
  cursor: pointer;  /* Show pointer on hover */
  transition: background-color 0.3s, transform 0.2s ease;  /* Smooth hover effect */
}

.update-btn:hover {
  background-color: #D79CFF;  /* Slightly darker purple on hover */
  transform: scale(1.05);  /* Slight scale effect on hover */
}

.update-btn:focus {
  outline: none;  /* Remove focus outline */
}

.update-btn:active {
  background-color: #BE86D6;  /* Even darker purple on click */
}

/* Mobile-friendly adjustments */
@media (max-width: 600px) {
  .settings-page {
    width: 100%; /* Full width */
  height: auto; /* Full height minus nav bar */
  }

  .settings-tabs {
    flex-direction: column; /* Stack tabs vertically */
    gap: 8px;
  }

  .settings-tabs button {
    padding: 10px 15px; /* Adjust button size */
    font-size: 14px; /* Smaller text for mobile */
  }

  .settings-section h2 {
    font-size: 18px; /* Smaller section heading */
  }

  .form-group {
    padding: 12px;
  }

  .form-group label {
    font-size: 12px; /* Smaller label font */
  }

  .form-group input,
  .form-group textarea {
    padding: 8px; /* Adjust input padding */
  }

  .char-count {
    font-size: 10px; /* Smaller char count */
  }

  .profile-photo img {
    width: 60px;
    height: 60px; /* Smaller profile image */
  }

  .checkbox-label {
    font-size: 14px; /* Smaller checkbox label */
  }

  .card {
    padding: 12px;
  }

  .card h2 {
    font-size: 20px; /* Smaller card title */
  }

  .card h3 {
    font-size: 14px; /* Smaller card subtitle */
  }

  .update-btn {
    padding: 10px 20px; /* Smaller update button */
    font-size: 14px; /* Smaller text */
  }
}
</style>