<template>
  <div class="sales-page">
    <header class="header">
      <div class="logo">
        <img src="\openpsychiclogo.png" alt="Logo" class="logo-img" />
        <span>OpenPsychic</span>
      </div>
      <nav class="nav-buttons">
        <router-link to="/psychic-signup" class="psychic-link"> Are You Psychic? Sign Up. </router-link>   
         <button @click="toggleAuthModal">Login </button>
      </nav>
      <LoginSignupModule v-if="showLogin" :isOpen="showLogin" @close="closeAuthModal" /> 
    </header>

    <section class="hero">
      <h1>Unlock Your Future with OpenPsychic</h1>
      <p>No Pay Per Minute. Enjoy free messages, reading requests, and live video session scheduling.</p>
      <button @click="scrollToSection('subscription')" class="cta-button">Join Now</button>
    </section>

    <section class="sponsored">
      <SponsoredPsychicsModule/>
    </section>

    <section id="subscription" class="subscriptions">
      <SubscriptionOffers/>
    </section>

    <section class="faq">
  <h2>Frequently Asked Questions</h2>
  <div class="faq-item" v-for="(item, index) in faqs" :key="index">
    <h3 @click="toggleFaq(index)">{{ item.question }}</h3>
    <p :class="{ open: item.open }">{{ item.answer }}</p> <!-- Use v-bind:class to toggle the open class -->
  </div>
</section>

    <footer class="footer">
      <p>&copy; 2025 OpenPsychic. All rights reserved.</p>
    </footer>
  </div>
</template>
<script>
import { getAuth, onAuthStateChanged } from 'firebase/auth';  // Correct import for Firebase Auth
import LoginSignupModule from './LoginModule.vue'; // Adjust path if necessary
import SponsoredPsychicsModule from './SponsoredPsychics.vue'; // Adjust path if necessary
import SubscriptionOffers from './SubscriptionOffers.vue';
import { doc, setDoc } from "firebase/firestore";
import { firestore, auth } from "@/firebase"; // Assuming you're importing firestore and auth
import { getMessaging, getToken } from "firebase/messaging";

export default {
  components: {
    LoginSignupModule,
    SponsoredPsychicsModule,
    SubscriptionOffers,
  },
  data() {
    return {
      isAuthenticated: false, // Track if the user is authenticated
      showLogin: false,
      faqs: [
        {
          question: "How does OpenPsychic work?",
          answer: "OpenPsychic is more than a platform—it's a gateway to limitless possibilities. We connect you with the best psychics on the market, offering live sessions, unlimited messaging, and personalized readings. You control your journey. No boundaries. No restrictions. We are the future of psychic services, and it’s just getting started."
        },
        {
          question: "What’s the subscription cost?",
          answer: "Just $29.99/month. Get access to everything we offer: unlimited messages, readings, and live sessions. No hidden fees, no pay-per-minute gimmicks. Full transparency. You pay once, and you’re in. Simple."
        },
        {
          question: "Are there extra fees for live sessions?",
          answer: "No extra fees. Once you’re subscribed, you’ve got full access—messages, readings, and live sessions included. You also have complete freedom to pay directly to your advisor in the way that works best for you: Stripe, PayPal, or Bitcoin. You choose how to pay, and we make sure it’s smooth."
        },
        {
          question: "Can I cancel my subscription anytime?",
          answer: "Of course. You’re in control. Cancel anytime, and your access stays active until the end of your billing cycle. No fine print. No drama. Just total freedom."
        },
        {
          question: "Are the advisors certified or verified?",
          answer: "Absolutely. Only the best make it on OpenPsychic. Every advisor goes through a rigorous verification process, ensuring they’re top-notch. You’ll always have access to high-quality, genuine guidance. This isn’t your typical psychic platform—it’s a place for real experts."
        },
        {
          question: "How do I book a live session?",
          answer: "Booking a session is as easy as it gets. Choose your advisor, pick a time, and lock it in. No hassle. No waiting around. Whether it’s planned or last-minute, you’re always just a click away from live psychic insights."
        },
        {
          question: "What if I’m not satisfied with a session?",
          answer: "If something doesn’t feel right, reach out. We want you to have the best experience possible. At OpenPsychic, we’ve got your back. We empower you to have a seamless, positive experience every time, ensuring that you get the insights you need. We’re about creating real value, and that means full satisfaction."
        },
        {
          question: "How do I contact customer support?",
          answer: "Fast support is always available. Reach us directly through the ‘Help’ section or email support@openpsychic.com. We’ll take care of you quickly—because we know you’ve got better things to do than wait."
        },
        {
          question: "Can I change my advisor?",
          answer: "Yes. You’re in complete control of who you connect with. If your current advisor isn’t the right fit, no problem. Find someone else. OpenPsychic gives you the freedom to choose who you trust."
        },
        {
          question: "How secure is my personal and payment information?",
          answer: "Your data is locked down. We use the latest encryption to keep your information safe. Your privacy is our priority, and we don’t share your details without your consent. Rest easy knowing your data is secure."
        },
        {
          question: "How do I pay for my psychic sessions?",
          answer: "You have complete control over how you pay. Use Stripe, PayPal, or even Bitcoin. Soon, we’ll be expanding with MetaMask integration, taking the future of payments to the next level. OpenPsychic is always ahead of the curve, ensuring you have seamless, flexible payment options—today and in the future."
        },
        {
          question: "What makes OpenPsychic different from other platforms?",
          answer: "OpenPsychic is a game-changer. We’re not bound by outdated models. We’re rapidly growing, building a community that’s shaping the future of psychic services. This isn’t just another platform—it’s a revolution. And we’re just getting started. Fast payments. Full control. Real experts. A future with crypto integration. The next big thing is happening here, and you’re invited to be part of it."
        }
      ]
    };
  },
  methods: {
    async requestNotificationPermission() {
      try {
        const permission = await Notification.requestPermission();
        return permission === "granted";
      } catch (error) {
        console.error("Error requesting notification permission:", error);
        return false;
      }
    },

    async saveFCMToken(userId) {
      try {
        const messaging = getMessaging();
        const token = await getToken(messaging, {
          vapidKey: "BKNMs85FDDonOIDoM9tiStSlGmmj7EeSAeEqazs0upWtuxiNLt7bChiOonRbVDcFvIpWkIKZEH2k1GtIXUG3fAQ", // Replace with actual VAPID key
        });

        if (token) {
          console.log("FCM Token:", token);
          await setDoc(doc(firestore, "users", userId), { fcmToken: token }, { merge: true });
        } else {
          console.warn("No FCM token received.");
        }
      } catch (error) {
        console.error("Error retrieving FCM token:", error);
      }
    },
    toggleAuthModal() {
      this.showLogin = !this.showLogin;  // Toggle modal visibility
    },
    closeAuthModal() {
      this.showLogin = false;  // Close modal when clicked
    },
    navigateTo(page) {
      console.log(`Navigating to ${page}`);
    },
    toggleFaq(index) {
      this.faqs[index].open = !this.faqs[index].open;
    },
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
    // Make this method async to ensure we wait for Firebase update before navigating
    async updateUserOnlineStatus(isOnline) {
      const user = auth.currentUser;
      if (user) {
        try {
          await setDoc(doc(firestore, "users", user.uid), { 
          isOnline: isOnline,
          lastOnline: new Date(), // Update the last online timestamp
        }, { merge: true });
        } catch (error) {
          console.error("Error updating online status: ", error);
        }
      }
    },

    // Use async/await in this method to ensure it waits for the Firebase update
    async checkUserAuthentication() {
      const authInstance = getAuth(); // Initialize Firebase Authentication instance
  
      // Optional: Listen for authentication state changes
      onAuthStateChanged(authInstance, async (user) => {
        if (!user) {
          // User is signed out, set online status to false
          this.isAuthenticated = false;
          await this.updateUserOnlineStatus(false); // Wait for status update
          this.$router.push({ path: "/" }); // Redirect to home or login
        } else {
          // User is signed in, set online status to true
          this.isAuthenticated = true;
          await this.updateUserOnlineStatus(true); // Wait for status update
          const hasPermission = await this.requestNotificationPermission();
          if (hasPermission) {
            await this.saveFCMToken(user.uid);
          }
          this.$router.push({ path: "/dashboard" }); // Redirect to dashboard
        }
      });
    },
  },
  created() {
    this.checkUserAuthentication();
  },
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.sales-page {
  font-family: 'Poppins', sans-serif;
  color: #333;
  background-color: #f2f2f2;
  width: 100%;
  overflow-x: hidden;
}

.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #16041E;
  color: #DB8FFF;
  box-shadow: 0px 2px 5px rgba(219, 143, 255, 0.3);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
}

.logo {
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: 700;
  color:#DB8FFF;
}

.logo-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.logo span {
  font-family: 'Uncial Antiqua', serif;
  font-size: 24px;
  color: #DB8FFF;
  letter-spacing: 1.5px;
  text-shadow: 0 0 10px rgba(219, 143, 255, 0.8), 0 0 20px rgba(219, 143, 255, 0.5);
  font-weight: 400;
  margin-left: 10px;
}

/* Stylish "Are you a Psychic?" Link */
.psychic-link {
  color: #DB8FFF; /* Vibrant lavender to match brand */
  font-size: 12px;
  margin-left: 20px;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  text-shadow: 0 0 8px rgba(219, 143, 255, 0.6);
}

.psychic-link:hover {
  color: #FFCD00; /* Gold on hover for contrast */
  text-shadow: 0 0 12px rgba(255, 205, 0, 0.8);
  transform: scale(1.05);
}

.nav-buttons button {
  background: none;
  border: 2px solid #3D005A;
  color: #3D005A;
  padding: 10px 25px;
  margin-left: 10px;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.nav-buttons button:hover {
  background-color: #DB8FFF;
  color: #fff;
}

.hero {
  width: 100%;
  height: 100vh;
  background: linear-gradient(rgba(14, 11, 22, 0.5), rgba(14, 11, 22, 0.5)), center/cover no-repeat;
  background-image: radial-gradient(circle at top, #c400ff, #33004d);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px 10%;
}

.hero h1 {
  font-size: 64px;
  margin-bottom: 20px;
}

.hero p {
  font-size: 22px;
  margin-bottom: 40px;
  max-width: 800px;
}

.cta-button {
  background-color: #ffca28;
  color: #333;
  padding: 18px 50px;
  border-radius: 30px;
  border: none;
  font-size: 20px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.cta-button:hover {
  transform: scale(1.1);
}

.pricing, .features, .faq {
  padding: 100px 10%;
  text-align: center;
}

.plans {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 40px;
}

.plan {
  background-color: #fff;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 300px;
  transition: transform 0.3s ease;
}

.plan:hover {
  transform: translateY(-10px);
}

.faq {
  background-color: #2a093a; /* Deep purple */
  padding: 40px 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  color: #ECC3FF; /* Light lavender */
}

.faq h2 {
  text-align: center;
  font-size: 2rem;
  color: #FFCD00; /* Gold color for the title */
  margin-bottom: 30px;
}

.faq-item {
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 8px;
  background-color: #3d0d57; /* Darker purple for each FAQ item */
  transition: background-color 0.3s ease;
}

.faq-item h3 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #DB8FFF; /* Lavender color for the questions */
  cursor: pointer;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: color 0.3s ease;
}

.faq-item h3:hover {
  color: #FFCD00; /* Change to gold when hovered */
}

.faq-item p {
  text-align: left; /* Left-align the answer text */
  font-size: 1rem;
  color: #A6EFCEFF; /* Light green for the answer text */
  margin-top: 10px;
  transition: opacity 0.3s ease;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.faq-item p.open {
  opacity: 1;
  max-height: 500px;
}

.footer {
  background-color: #16041E; /* White background for the footer */ 
  color: #DB8FFF;
  height: 40px; /* Set footer height */
  padding: 10px 20px; /* Padding for the footer */
  position: fixed; /* Fixed positioning for footer */
  bottom: 0; /* Position at the bottom of the parent container */
  margin-top: 10px; /* Add top margin for spacing */
  left: 0;
  width: 100%; /* Full width */
  display: flex; /* Flexbox for layout */
  justify-content: center; /* Center content */
  align-items: center; /* Center align items */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow on top */
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 36px;
  }
  .plans {
    flex-direction: column;
    align-items: center;
  }
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-buttons {
    margin-top: 10px;
    width: 100%;
    justify-content: flex-end;
    gap: 10px;
  }
}
</style>