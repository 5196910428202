<template>
  <div id="app">
    <section class="subscription-options">
      <h1 class="main-heading">Your Moment Has Arrived.</h1>
      <h2 class="sub-heading">Step Into a World of Infinite Insights with Our Elite Psychic Advisors</h2>
      
      <!-- <div class="plan">
        <h3 class="plan-title">100% Free </h3>
        <p class="price">Free</p>
        <button @click="toggleAuthModal" class="cta-button">Get Started for Free</button>
        <p class="plan-includes">Includes:</p>
        <ul class="benefit-list">
          <li>Unlimited Access to Top-Rated Psychic Advisors</li>
          <li>Exclusive Free Messages & Requests</li>
          <li>Priority Scheduling for Live Video Sessions</li>
          <li>Artificial Intelligent insights</li>
          <li>24/7 Support for Your Mystical Needs</li>
        </ul>
        <p class="extra-info">No payment required.</p>
        <a href="/" class="learn-more">Learn More</a>
      </div>
       -->
      <div class="plan">
        <h3 class="plan-title">OpenPsychic Unlimited</h3>
        <p class="price">$29.99 month</p>
        <button @click="toggleAuthModal" class="cta-button">Get Premium Access</button>
        <p class="plan-includes">Includes:</p>
        <ul class="benefit-list">
          <li>Unlimited Access to the most trusted Psychic Advisors</li>
          <li>Exclusive Free Messaging – No time limits, no restrictions</li>
          <li>Priority Scheduling with real-time calendar updates </li>
          <li>Private & Personalized Reading Requests  </li>
          <li>24/7 Dedicated Support for all your mystical needs </li>
        </ul>
        <p class="extra-info">No hidden fees.</p>
        <a href="/" class="learn-more">Learn More</a>
      </div>
      <LoginSignupModule v-if="showLogin" :isOpen="showLogin" @close="closeAuthModal" ></LoginSignupModule>
    </section>
  
    <footer>
      <p>All plans are subject to applicable taxes and fees.</p>
    </footer>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import LoginSignupModule from './LoginModule.vue'; // Adjust path if necessary
export default {
  components: {
    LoginSignupModule,
  },
  data() {
    return {
      showLogin: false,
      stripePromise: loadStripe("pk_live_51QKSz3J1NhX9kaJ0eGoIERh0iBQsqjNC9WvOvwUhnec9zSwSDyZz6ELhWWldFaO6AejMGnpYcgZehswg18tcdvKz00ZfGGN7Nb"), // Use your Stripe public key here
    };
  },
  methods: {
    toggleAuthModal() {
      this.showLogin = !this.showLogin;  // Toggle modal visibility
    },
    closeAuthModal() {
      this.showLogin = false;  // Close modal when clicked
    },
    navigateTo(page) {
      console.log(`Navigating to ${page}`);
    },
    async startCheckout() {
      const stripe = await this.stripePromise;

      // Call your backend to create a checkout session
      const response = await fetch("/api/create-checkout-session", {
        method: "POST",
      });

      const session = await response.json();

      // Redirect to Stripe checkout
      const { error } = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (error) {
        console.error("Error redirecting to checkout: ", error);
      }
    },
  },
};
</script>

<style scoped>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  text-align: center;
  color: #ECC3FF;
  background-color: #2a093a;
  padding: 10px 5px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-heading {
  font-size: 2rem;
  color: #FFCD00;
  font-weight: bold;
  margin-bottom: 10px;
}

.sub-heading {
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: #FFCD00;
}

.highlight-heading {
  font-size: 1.8rem;
  font-weight: bold;
  color: #FFCD00;
  margin-bottom: 20px;
}

.plan {
  background-color: transparent;
  padding: 20px;
  border: 2px solid #6a4e94;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  margin-bottom: 20px;
  width: 100%;
}

.plan-title {
  font-size: 1.4rem;
  color: #FFCD00;
  font-weight: bold;
  margin-bottom: 10px;
}

.price {
  font-size: 1.2rem;
  color: #d3b1e5;
  margin-bottom: 15px;
}

.cta-button {
  background-color: #FFCD00;
  color: #2a093a;
  border: none;
  padding: 10px 25px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 15px;
  width: 100%;
}

.cta-button:hover {
  background-color: #e6b800;
}

.benefit-list li {
  font-size: 1rem;
  color: #fff;
  margin-bottom: 5px;
}

.extra-info, .learn-more {
  font-size: 0.9rem;
  color: #d3b1e5;
}

footer {
  padding: 10px;
  font-size: 0.8rem;
  color: #d3b1e5;
}

@media (min-width: 600px) {
  .main-heading {
    font-size: 2.5rem;
  }
  .sub-heading {
    font-size: 1.5rem;
  }
  .highlight-heading {
    font-size: 2.2rem;
  }
  .plan {
    max-width: 450px;
  }
  .cta-button {
    font-size: 1.2rem;
    padding: 12px 30px;
  }
}

@media (max-width: 599px) {
  #app {
    padding: 10px 20px;
  }

  .main-heading {
    font-size: 1.8rem;
  }
  .sub-heading {
    font-size: 1rem;
  }
  .highlight-heading {
    font-size: 1.6rem;
  }

  .cta-button {
    font-size: 1.1rem;
    padding: 12px;
  }

  .plan-title {
    font-size: 1.2rem;
  }
}
</style>
